//@ts-nocheck

import React, {lazy,Suspense} from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loading from "../components/Loading"

const CatteryView = lazy(() => import('../views/manage'))



export default function RouteApp(props) {
  return (
      <div className='route-dom'>
        <ConnectedRouter history={props.history}>
          <Router>
            <Suspense fallback={<Loading />} >
            <Switch>
              <Route path="/cattery" component={CatteryView} />
              <Route path="/" component={CatteryView} />
            </Switch>
            </Suspense>
          </Router>
        </ConnectedRouter>
 
      </div>
  )
}
