/* eslint-disable*/ 
/*
 * @Descripttion:
 * @version:
 * @Author: NaiBo
 * @Date: 2021-04-07 14:39:34
 * @LastEditors: NaiBo
 * @LastEditTime: 2021-04-13 14:53:40
 */

import dva from 'dva'
import { createBrowserHistory } from 'history'

import { importAll } from './utils/import-all'
import { registerModel } from './utils/register-model'
import router from './route/index'
import "./index.css"


export const browserHistory = createBrowserHistory()

// 1. Initialize
export const app = dva({
  history: browserHistory,
  initialState: {},
})
window.g_history = browserHistory
window.g_app = app

// app.use(createLoading())

// 3. Model
// const models = importAll(require.context('@/models', false, /^((?!noimport).)*\.ts$/))
// registerModel(app, models)

// 4. Router
app.router(router)

// 5. Start
app.start('#root')

// 6. Things to be done after app start
// 持久化store